/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Link from 'gatsby-link';
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { ThemeProvider } from 'styled-components';

import theme from '../stylesheets/theme';

import Layout from '../components/layout';

const VarausJaPeeruStyles = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .terms-container {
    margin: 150px auto 0;
    display: flex;
    flex-direction: column;
    max-width: 800px;
  }
`;

const VarausJaPeeru = () => (
  <Layout
    seo={{
      title: 'Discover Lapland - Varaus- ja peruutusheidot'
      // keywords={post.tags}
      // url={`https://www.discoverlapland.fi/blog`}
      // image={`https:${post.headerImage.file.url}`}
    }}
  >
    <ThemeProvider theme={theme}>
      <VarausJaPeeruStyles>
        <div className="terms-container">
          <h2>Rekisteri- ja tietosuojaseloste</h2>
          <br />
          <p>
            Tämä on Discover Laplandin henkilötietolain (10 ja 24 §) ja EU:n
            yleisen tietosuoja-asetuksen (GDPR) mukainen rekisteri- ja
            tietosuojaseloste. Laadittu 27.5.2020.{' '}
          </p>
          <br />
          <h3>1. Rekisterinpitäjä</h3>
          <br />
          <p>
            Discover LaplandOnnenpolku 30 B,
            <br />
            99130 Sirkka
            <br />
            Y-tunnus: 2232108-3
          </p>
          <br />
          <h3>2. Rekisteristä vastaava yhteyshenkilö</h3>
          <br />
          <p>
            Anette Latva-Piikkilä, puh. 040 772 4124,
            anettelatvapiikkila@gmail.com
          </p>
          <br />
          <h3>3. Rekisterin nimi</h3>
          <br />
          <p>Yrityksen asiakasrekisteri.</p>
          <br />
          <h3>4. Oikeusperuste ja henkilötietojen käsittelyn tarkoitus</h3>
          <br />
          <p>
            EU:n yleisen tietosuoja-asetuksen mukainen oikeusperuste
            henkilötietojen käsittelylle on
            <br />
            - henkilön suostumus (vapaaehtoinen)
            <br />
            - rekisterinpitäjän oikeutettu etu (työsuhde).
            <br />
            Henkilötietojen käsittelyn tarkoitus on yhteydenpito asiakkaisiin
            sekä asiakassuhteen ylläpito.
            <br />
            Tietoja ei käytetä automatisoituun päätöksentekoon tai
            profilointiin.
          </p>
          <br />
          <h3>5. Rekisterin tietosisältö</h3>
          <br />
          <p>
            Rekisteriin tallennettavia tietoja ovat: henkilön nimi, ikä,
            yhteystiedot (puhelinnumero, sähköpostiosoite, paikkakunta),
            aikaisempi kokemus aktiviteeteistä sekä osallistujan yleinen
            terveydentila. Tiedot säilytetään yrityksen tietokoneella
            Excel-taulukossa ilmoittautumisesta aina viikko retken päättymiseen,
            jonka jälkeen asiakastiedot poistetaan.{' '}
          </p>
          <br />
          <h3>6. Säännönmukaiset tietolähteet</h3>
          <br />
          <p>
            Rekisteriin tallennettavat tiedot saadaan asiakkaalta sähköpostitse.
          </p>
          <br />
          <h3>
            7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai
            ETA:n ulkopuolelle
          </h3>
          <br />
          <p>
            Tietoja ei luovuteta säännönmukaisesti muille tahoille. Tietoja
            voidaan julkaista siltä osin kuin niin on sovittu asiakkaan kanssa.
          </p>
          <br />
          <h3>8. Rekisterin suojauksen periaatteet</h3>
          <br />
          <p>
            Rekisterin käsittelyssä noudatetaan huolellisuutta ja
            tietojärjestelmien avulla käsiteltävät tiedot suojataan
            asianmukaisesti. Kun rekisteritietoja säilytetään
            Internet-palvelimilla, niiden laitteiston fyysisestä ja
            digitaalisesta tietoturvasta huolehditaan asiaankuuluvasti.
            Rekisterinpitäjä huolehtii siitä, että tallennettuja tietoja sekä
            palvelimien käyttöoikeuksia ja muita henkilötietojen turvallisuuden
            kannalta kriittisiä tietoja käsitellään luottamuksellisesti ja vain
            niiden työntekijöiden toimesta, joiden työnkuvaan se kuuluu.
          </p>
          <br />
          <h3>9. Tarkastusoikeus ja oikeus vaatia tiedon korjaamista</h3>
          <br />
          <p>
            Jokaisella rekisterissä olevalla henkilöllä on oikeus tarkistaa
            rekisteriin tallennetut tietonsa ja vaatia mahdollisen virheellisen
            tiedon korjaamista tai puutteellisen tiedon täydentämistä. Mikäli
            henkilö haluaa tarkistaa hänestä tallennetut tiedot tai vaatia
            niihin oikaisua, pyyntö tulee lähettää
            kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää
            tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä.
            Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa
            säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
          </p>
          <br />
          <h3>10. Muut henkilötietojen käsittelyyn liittyvät oikeudet</h3>
          <br />
          <p>
            Rekisterissä olevalla henkilöllä on oikeus pyytää häntä koskevien
            henkilötietojen poistamiseen rekisteristä ("oikeus tulla
            unohdetuksi"). Niin ikään rekisteröidyillä on muut 
            <u>
              <a href="https://eur-lex.europa.eu/legal-content/FI/TXT/?uri=CELEX%3A32016R0679#d1e2144-1-1">
                EU:n yleisen tietosuoja-asetuksen mukaiset oikeudet
              </a>
            </u>
             kuten henkilötietojen käsittelyn rajoittaminen tietyissä
            tilanteissa. Pyynnöt tulee lähettää
            kirjallisesti rekisterinpitäjälle. Rekisterinpitäjä voi pyytää
            tarvittaessa pyynnön esittäjää todistamaan henkilöllisyytensä.
            Rekisterinpitäjä vastaa asiakkaalle EU:n tietosuoja-asetuksessa
            säädetyssä ajassa (pääsääntöisesti kuukauden kuluessa).
          </p>
        </div>
      </VarausJaPeeruStyles>
    </ThemeProvider>
  </Layout>
);

export default VarausJaPeeru;
